<template>
  <v-container fluid>
    <v-row>
      <v-col class="mt-3" cols="4">
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="showSection = !showSection"
        >
          {{ showSection ? "Hide" : "Add Sales Employee" }}
          <v-icon>{{ showSection ? "mdi-minus" : "mdi-plus" }}</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="showSection" cols="8">
        <div class="mt-3">
          <v-autocomplete
            outlined
            dense
            v-model="record.employees"
            :items="salesEmployees"
            attach
            chips
            clearable
            @input="searchInput = null"
            :search-input.sync="searchInput"
            deletable-chips
            label="Sales Employees"
            item-text="SlpName"
            item-value="SlpCode"
            multiple
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end" v-if="showSection">
      <v-btn
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="targetData"
          :search="search"
          :footer-props="{
            itemsPerPageOptions: [5, 100, 150, { text: 'All', value: -1 }],
            showCurrentPage: true,
          }"
        >
          <!-- outlet template -->
          <template v-slot:item.id="props">
            <v-btn
              color="flatButton"
              text
              @click="
                confirmDelete = true;
                itemToDelete = props.item.id;
              "
            >
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Remove</v-card-title>
        <v-card-text
          >Are you sure you want to Remove the sales employee?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDelete = false"
            >No, Cancel</v-btn
          >
          <v-btn
            color="red"
            text
            @click="
              deleteItem(itemToDelete);
              deleteDialog = false;
            "
            >Yes, Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    <script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },

  data: () => ({
    dataItem: {},
    Items: [],
    searchInput: null,
    record: {},
    search: null,
    showItems: [],
    targetData: [],
    salesEmployees: [],
    SlpName: null,
    loader: false,
    showSection: false,
    itemToDelete: null,
    confirmDelete: false,
    headers: [
      { text: "Sales Employee Name", value: "employees.SlpName" },
      { text: "Action", value: "id" },
    ],
  }),

  watch: {
    "$route.params.id": {
      handler: "getSlpEmp",
      immediate: true,
    },
  },
  methods: {
    getSlpEmp(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/target_employeese/${val}`)
        .then((res) => {
          console.log(res.ResponseData, "emp target");
          self.targetData = res.ResponseData;

          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          this.loading = false;
        });
    },
    sendData() {
      this.loading = true;
      const data = {
        employees: this.record.employees,
        target_setup_id: this.$route.params.id,
      };
      const url = "/add_slp_to_target";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          this.showSection = false;
          if (res.ResultCode == 1200) {
            this.getSlpEmp(this.$route.params.id);
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
      this.loading = false;
    },
    deleteItem(itemId) {
      this.loading = true;
      if (!itemId) return;
      const url = `/remove_target_slp/${itemId}`;
      this.$store
        .dispatch("put", { url })
        .then((res) => {
          if (res) {
            this.getSlpEmp(this.$route.params.id);
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
      this.loading = false;
      this.confirmDelete = false;
    },

    getEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getEmployees();
  },
};
</script>
   